import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import './ContactContent.css';
function ContactContent() {
  const [formData, setFormData] = useState({
    name: "",
    organization: "",
    email: "",
    message: ""
  });
  const [submitted, setSubmitted] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaValue) {
      // Handle the missing reCAPTCHA
      console.error("Please verify the reCAPTCHA.");
      return;
    }

    // Email sending logic, possibly with the captchaValue

    // Reset the reCAPTCHA after submitting the form
    setCaptchaValue(null);
  }

  return (
    <div className="contact-container">
      <h1>Want to work with us? Let us know!</h1>

      {submitted ? (
        <div className="thank-you-message">Thank you for reaching out! We will get back to you ASAP!</div>
      ) : (
        <form onSubmit={handleSubmit} className="contact-form">
          <input type="text" name="name" placeholder="Name" required onChange={handleInputChange} />
          <input type="text" name="organization" placeholder="Organization (optional)" onChange={handleInputChange} />
          <input type="email" name="email" placeholder="Email" required onChange={handleInputChange} />
          <textarea name="message" placeholder="Message" required onChange={handleInputChange}></textarea>
          <ReCAPTCHA
            sitekey="6Lc6vbwnAAAAAMMZ7HLq6DqYDOPEnU8sbsloXHiB"
            onChange={handleCaptchaChange}
          />
          <button type="submit" className="submit-button">Submit</button>
        </form>
      )}
    </div>
  );
}

export default ContactContent;
