import React, { useEffect } from 'react';

function HomeContent({ onGameClick, onAboutClick, onTeamClick, onContactClick }) {

  useEffect(() => {
    const complaintsElement = document.querySelector("#complaints");

    function handleComplaintsClick(e) {
      console.log('Event triggered', e);
      console.log('Target:', e.target);
      console.log('CurrentTarget:', e.currentTarget);
      e.preventDefault();
      e.target.classList.add("crashing");
      setTimeout(() => {
        e.target.style.display = "none";
        document.body.classList.add("shake-screen");
        setTimeout(() => {
          document.body.classList.remove("shake-screen");
        }, 500);
      }, 1000);
    }

    if (complaintsElement) {
      complaintsElement.removeEventListener("click", handleComplaintsClick);
      complaintsElement.addEventListener("click", handleComplaintsClick);
    }

    return () => {
      if (complaintsElement) {
        complaintsElement.removeEventListener("click", handleComplaintsClick);
      }
    }
  }, []);

  return (
    <div id="links">
      <button onClick={onGameClick} className="bouncy">
        <span style={{ '--i': '1' }}>O</span>
        <span style={{ '--i': '2' }}>u</span>
        <span style={{ '--i': '3' }}>r</span>
        <span style={{ '--i': '4' }}>&nbsp;</span>
        <span style={{ '--i': '5' }}>G</span>
        <span style={{ '--i': '6' }}>a</span>
        <span style={{ '--i': '7' }}>m</span>
        <span style={{ '--i': '8' }}>e</span>
        <span style={{ '--i': '9' }}>s</span>
      </button>
      <button onClick={onAboutClick} className="bouncy">
        <span style={{ '--i': '1' }}>A</span>
        <span style={{ '--i': '2' }}>b</span>
        <span style={{ '--i': '3' }}>o</span>
        <span style={{ '--i': '4' }}>u</span>
        <span style={{ '--i': '5' }}>t</span>
        <span style={{ '--i': '6' }}>&nbsp;</span>
        <span style={{ '--i': '7' }}>U</span>
        <span style={{ '--i': '8' }}>s</span>
      </button>
      <button onClick={onTeamClick} className="bouncy">
        <span style={{ '--i': '1' }}>O</span>
        <span style={{ '--i': '2' }}>u</span>
        <span style={{ '--i': '3' }}>r</span>
        <span style={{ '--i': '4' }}>&nbsp;</span>
        <span style={{ '--i': '5' }}>T</span>
        <span style={{ '--i': '6' }}>e</span>
        <span style={{ '--i': '7' }}>a</span>
        <span style={{ '--i': '8' }}>m</span>
      </button>
      <button onClick={onContactClick} className="bouncy">
        <span style={{ '--i': '1' }}>C</span>
        <span style={{ '--i': '2' }}>o</span>
        <span style={{ '--i': '3' }}>n</span>
        <span style={{ '--i': '4' }}>t</span>
        <span style={{ '--i': '5' }}>a</span>
        <span style={{ '--i': '6' }}>c</span>
        <span style={{ '--i': '7' }}>t</span>
      </button>
      <button id="complaints">Complaints</button>
      <div id="social-links">
        <a href="https://twitter.com/YaruGames" rel="noreferrer" target="_blank" aria-label="Twitter">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="https://discord.gg/pcFX5SmrXr" rel="noreferrer" target="_blank" aria-label="Discord">
          <i className="fab fa-discord"></i>
        </a>
        <a href="https://github.com/Yaru-Partners" rel="noreferrer" target="_blank" aria-label="GitHub">
          <i className="fab fa-github"></i>
        </a>
      </div>
    </div>
  );
}

export default HomeContent;
