import React, { useEffect, useState } from 'react';
import './GamesContent.css';

const gameCards = [
  { id: 1, imageUrl: './games/game1.png', link: '/game1' },
  { id: 2, imageUrl: './games/game2.png', link: '/game2' },
  { id: 3, imageUrl: './games/game2.png', link: '/game2' },
  { id: 4, imageUrl: './games/game1.png', link: '/game2' },
  { id: 5, imageUrl: './games/game2.png', link: '/game2' },
  { id: 6, imageUrl: './games/game1.png', link: '/game2' },
  // ... other games
];

function GamesContent() {
  const [visibleCards, setVisibleCards] = useState([]);

  useEffect(() => {
    gameCards.forEach((game, index) => {
      setTimeout(() => {
        setVisibleCards(prevVisibleCards => [...prevVisibleCards, game.id]);
      }, index * 100);
    });
  }, []);

  return (
    <div className="game-grid">
      {gameCards.map(game => (
        <div
          key={game.id}
          className={`game-card ${visibleCards.includes(game.id) ? 'fadeIn' : 'hidden'}`}
        >
          <a href={game.link}>
            <img src={game.imageUrl} alt={`Game ${game.id}`} />
          </a>
        </div>
      ))}
    </div>
  );
}

export default GamesContent;
