import { motion } from "framer-motion";
import React from 'react';
import './App.css';
import Header from './Header';
import MainContent from './MainContent';

function App() {
  return (
    <div>
      <motion.div layout layoutId="header">
        <Header />
      </motion.div>
      <MainContent />
    </div>
  );
}

export default App;
