import 'font-awesome/css/font-awesome.min.css';
import React from 'react';
import './BackButton.css';

function BackButton({ onClick }) {
  return (
    <button className="back-button" onClick={onClick}>
      <span style={{ '--i': '0' }}><i className="fa fa-arrow-left" aria-hidden="true"></i></span>
      <span style={{ '--i': '1' }}>&nbsp;</span>
      <span style={{ '--i': '2' }}>G</span>
      <span style={{ '--i': '3' }}>o</span>
      <span style={{ '--i': '4' }}>&nbsp;</span>
      <span style={{ '--i': '5' }}>B</span>
      <span style={{ '--i': '6' }}>a</span>
      <span style={{ '--i': '7' }}>c</span>
      <span style={{ '--i': '8' }}>k</span>
    </button >
  );

}


export default BackButton;
