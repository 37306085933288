import React, { useState } from 'react';
import AboutUsContent from './AboutUsContent';
import BackButton from './BackButton';
import ContactContent from './ContactContent';
import GamesContent from './GamesContent';
import HomeContent from './HomeContent';

function MainContent() {
  const [view, setView] = useState('home');

  const renderContent = () => {
    switch (view) {
      case 'home':
        return (
          <HomeContent
            onGameClick={() => setView('games')}
            onAboutClick={() => setView('about')}
            onTeamClick={() => setView('team')}
            onContactClick={() => setView('contact')}
          />
        );
      case 'games':
        return <div>
          <BackButton onClick={() => setView('home')} />
          <GamesContent> </GamesContent>
        </div>;
      case 'about':
        return <div>
          <BackButton onClick={() => setView('home')} />
          <AboutUsContent> </AboutUsContent>
        </div>;
      case 'team':
        return <div><BackButton onClick={() => setView('home')} /></div>;
      case 'contact':
        return <div>
          <BackButton onClick={() => setView('home')} />
          <ContactContent> </ContactContent>
        </div>;
      default:
        return null;
    }
  };

  return (
    <div id="container">
      <div>
        {renderContent()}
      </div>
    </div>
  );
}

export default MainContent;