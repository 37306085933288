import { motion } from 'framer-motion';
import React from 'react';
import './AboutUsContent.css';

function AboutUsContent() {
  return (
    <div className="about-container">
      <motion.h1
        className="animated-text"
        initial={{ y: 10 }}
        animate={{ y: -15 }}
        transition={{ repeat: Infinity, duration: 1, repeatType: 'reverse' }}
      >
        We make games!
      </motion.h1>

      <p className="about-text">
        At Yaru! Games, we're passionate about creating captivating gaming experiences for all ages. Specializing in Minecraft Marketplace content, we collaborate closely with partners to deliver high-quality experiences. Our history working with Mojang also enhances our expertise in marketplace content.
        <br /><br />
        Beyond Minecraft, our creativity extends to diverse gaming landscapes, from pixel-perfect platformers to 3D action games.
      </p>
    </div>
  );
}

export default AboutUsContent;
