import { motion } from "framer-motion";
import React, { useEffect } from 'react';
import './Header.css';
import logo from "./assets/images/branding/yarubannerp1.png";

function Header() {
  useEffect(() => {
    const bannerImage = document.getElementById("header-image");

    function handleBannerClick() {
      document.body.classList.add("shake-screen");
      setTimeout(() => {
        document.body.classList.remove("shake-screen");
      }, 500);
    }

    bannerImage.addEventListener("click", handleBannerClick);

    return () => {
      bannerImage.removeEventListener("click", handleBannerClick);
    };
  }, []);

  return (
    <motion.div id="header" layout layoutId="header">
      <img id="header-image" src={logo} alt="Yaru! Games" />
    </motion.div>
  );
}

export default Header;
